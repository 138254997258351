<template>
<div class="app-page-2">
  <div class="container">
    <div class="mineContainer">
      <Course v-if="type === 0" />
      <Orders v-if="type === 1" />
      <Protocol v-if="type === 2" />
      <ModifyPwd v-if="type === 3" />
      <Question v-if="type ===4" />
      <EditInfo v-if="type ===5" />
    </div>
  </div>
</div>
</template>
<script>
import Course from '@/components/mine/course.vue'
import Orders from '@/components/mine/orders.vue'
import Protocol from '@/components/mine/protocol.vue'
import Question from '@/components/mine/question.vue'
import ModifyPwd from '@/components/mine/ModifyPwd.vue'
import EditInfo from '@/components/mine/editInfo.vue'
export default {
  middleware: 'accountVerification',
  name:'course',
  components:{
    Course,
    Orders,
    Protocol,
    Question,
    ModifyPwd,
    EditInfo
  },
  data() {
    return {
      type: -1
    }
  },
  mounted(){
    this.type = +this.$route.query.type
  },
 
}
</script>
<style lang="less" scoped>
.mineContainer{
  width:100%;;
}

</style>
