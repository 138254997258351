<!--疑问与帮助-->
<template>
  <van-list
    v-model="listLoading"
    :finished="finished"
    @load="getList"
  >
    <div class="questionContain">
      <div class="question_list">
        <ul>
          <li v-for="(item, index) of list" :key="index">
            <i></i>
            <div class="question_right">
              <p>{{item.question}}</p>
              <span>{{item.answer}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </van-list>
</template>
<script>
import { getqQuestionHelp } from '@/api/api'
export default {
  name:'question',
  data() {
    return {
      params: {
        limit: 10,
        page: 1,
      },
      finished: false,
      listLoading: false,
      list: [],
    }
  },
  methods: {
    getList() {
      this.listLoading = true;
      getqQuestionHelp(this.params).then(res => {
        this.list = this.list.concat(res.data || []);
        if (res.data.length < this.params.limit) this.finished = true;
        this.params.page ++;
      })
    }
  },
}
</script>
<style lang="less">
.questionContain{
  padding:0 3%;
  overflow: hidden;
  background:#fff;
  .question_list{
    ul{
      margin:0;
      padding:0;
      li{
        width:100%;
        cursor: pointer;
        display: flex;
        // border-bottom:1px solid #E8E8E8;
        i{
          display:inline-block;
          float: left;
          width: 0.4rem;
          height: 0.4rem;
          background: url(../../assets/images/icon/icon-13.png) center no-repeat;
          margin-top:0.4rem;
          margin-right:0.2rem;
          background-size: 100% 100%;
        } 
        .question_right{
          width: 100%;
          overflow: hidden;
          padding:0.3rem 0;
          
          p{
            font-size: 16px;
            line-height: 0.5rem;
            color:#333333;
            text-align: left;
            margin-bottom:0.15rem;
          }
          span{
            display: block;
            font-size: 14px;
            color:#999999;
            line-height: 0.45rem;
          }
        }
      }
    } 
  }
}
</style>
