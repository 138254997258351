<template>
  <div class="form">
    <van-form ref="passwoForm" :model="passwoForm" label-width="90px">
      <div class="input-box first-box">
        <span>原密码：</span>
        <input v-model="passwoForm.oldPassword" type="password" placeholder="请输入原密码" />
      </div>
      <div class="input-box newPassword">
        <span>新密码：</span>
        <input type="password" v-model="passwoForm.newPassword" placeholder="请输入新密码" />
      </div>
      <div class="input-box">
        <span>确认新密码：</span>
        <input type="password" v-model="passwoForm.newAgainPassword" placeholder="请再次输入新密码" />
      </div>

      <div class="sure-btn">
        <span @click="changePassword" class="changeBtn">确认修改</span>
      </div>
    </van-form>
  </div>
</template>

<script>
import { changePassword } from '@/api/api'
const PASSWORDFORM = {
  oldPassword: '',
  newPassword: '',
  newAgainPassword: ''
}
export default {
  name: "ModifyPwd",
  data() {
    return {
      passwoForm: {},
      changeRules: {
        oldPassword: [
          { required: true, message: '请填写原密码', trigger: 'change' }
        ],
      }
    }
  },
  methods:{
    resetForm() {
      this.passwoForm = { ...PASSWORDFORM }
      this.$nextTick(() => {
        this.$refs['passwoForm'].clearValidate()
      })
    },

    async changePassword(){
      let params = this.passwoForm
      let response = await changePassword(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$toast(response.returnMsg)
      this.$store.commit('user/logout')
      this.$nextTick(() => {
        this.$router.push({path: `/mine`})
      })
    }
    
  }
}
</script>
<style lang="less" scoped>
.form{
  margin-bottom: 25px;
  padding:0.5rem 3%;
  background:#ffffff;
}
.form .input-box{
  background:#fff;
  display: flex;
  align-items: center;
  height:1rem;
  line-height: 1rem;
  border-bottom:1px solid #f2f2f5;
  padding:0 0.2rem;
}
.form .input-box span{
  font-size: 14px;
  color:#333;
  width: 2rem;
  text-align: left;
}
.form .input-box input{
  font-size: 14px;
}
.sure-btn span{
  display: block;
  width:94%;
  height:0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background:#FF3F47;
  color:#fff;
  font-size: 14px;
  margin:1rem auto;
  border-radius: 10px;
}
</style>
