<template>
  <div class="form" v-if="infoForm">
    <van-form ref="infoForm" :model="infoForm" label-width="90px">
        <div class="input-box img-box">
            <span>头像</span>
            <div class="headimg">
                <img v-if="infoForm.headImg" :src="infoForm.headImg" class="avatar">
            </div>
        </div>
        <div class="input-box ">
            <span>昵称</span>
            <input v-model="infoForm.name" disabled />
        </div>
        <div class="input-box ">
            <span>性别</span>
            <span>{{infoForm.sex | sexFilter}}</span>
        </div>
        

        <!-- <van-field
            v-model="infoForm.name"
            name="昵称"
            label="昵称"
            disabled 
        />
        <van-field name="radio" label="性别">
            <template #input>
                <van-radio-group v-model="infoForm.sex" direction="horizontal">
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field name="uploader" label="文件上传">
            <template #input>
                <van-uploader :v-model="uploadImage" :after-read="onRead" accept="image/*" />
            </template>
        </van-field> -->

      <div class="sure-btn" @click="logout()">
          <span>退出登录</span>
        </div>
    </van-form>
  </div>
</template>

<script>
import { updateUserInfo,logout } from '@/api/api'
export default {
  name: "ModifyPwd",
  data() {
    return {
      infoForm: {},
      uploadImage:'',
      changeRules: {
        name: [
          { required: true, message: '请填写昵称', trigger: 'change' }
        ],
      }
    }
  },
  mounted(){
    let userinfo = this.getUserInfo()
    if(!userinfo) {
      this.$nextTick(() => {
        this.$router.push({path: `/course`})
      })
    }
  },
  methods:{
    getUserInfo() {
        if(this.$store.state.userInfo) {
            this.infoForm = JSON.parse( JSON.stringify(this.$store.state.userInfo))
            return this.$store.state.userInfo || null
            
        } else {
            return null
        }
    },

    async logout() {
        // let response = await this.$axios.post(logout)
        // if(+response.returnCode !== 10001) {
        //     this.$toast(response.returnMsg)
        //     // return false
        // }
        this.$store.commit('clearUserInfo', null)
        this.$toast('退出成功')
        this.$nextTick(() => {
          this.$router.push({path: `/course`})
        })
    },

//     onRead(file){
//        console.log(file);
// 　　　  var formData = new FormData(); 
//     　　formData.append('file', file.file); 
//         this.axios.post('http://web-platfrom.jsbxgwy.com/jsplatfrom-web/api/upload/file',　　formData)
//         .then((res) => {
//             console.log(res)
//         })
//     },

    // resetForm() {
    //   this.infoForm = { ...INFOFORM }
    //   this.$nextTick(() => {
    //     this.$refs['infoForm'].clearValidate()
    //   })
    // },

    async changeInfo(){
      let params = this.infoForm
      let response = await this.$axios.post(updateUserInfo, null, {params})
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$toast(response.returnMsg)
      this.$nextTick(() => {
        window.location.reload()
      })
    }
    
  },
  filters: {
      sexFilter(val) {
        if(val===0) {
            return '男'
        } else if(val===1) {
            return '女'
        }
      },
    }
}
</script>
<style lang="less" scoped>
.form{
  margin-bottom: 25px;
  padding:0.5rem 3%;
  background:#ffffff;
}
.form .input-box{
  background:#fff;
  display: flex;
  align-items: center;
  height:1rem;
  line-height: 1rem;
  border-bottom:1px solid #f2f2f5;
  padding:0 0.2rem;
}
.form .img-box{
  height:1.5rem;
  line-height: 1.5rem;
}
.form .input-box span{
  font-size: 14px;
  color:#333;
  width: 2rem;
  text-align: left;
}
.form .input-box input{
  font-size: 14px;
}
.sure-btn span{
  display: block;
  width:94%;
  height:0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background:#FF3F47;
  color:#fff;
  font-size: 14px;
  margin:1rem auto;
  border-radius: 10px;
}
.headimg{
  height:1.5rem;
  line-height: 1.5rem;
}
.headimg img{
    width:1rem;
    height:1rem;
    border-radius: 50%;
    margin-top: 0.25rem;
}
</style>
