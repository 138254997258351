<!--我的课程-->
<template>
  <div>
    <div class="courseContain">
      <div class="course_list">
        <ul class="ul">
          <van-list
            v-model="loading"
            :finished="finished"
            @load="getCourseGroup"
          >
          <li class="li" v-for="(item, index) of courseGroupList" :key="index" @click="jumpDetail(item)">
            <div class="tag" v-if="item.hotLabel">
              <p class="tag-left"></p>
              <p class="tag-center">{{item.hotLabel}}</p>
              <p class="tag-right"></p>
            </div>
              <div class="title">{{item.courseGroupName}}</div>
              <p class="tip"> {{item.classTime}}</p>
              <div class="bottom-box">
                <div class="teacher-list" >
                  <div class="teacher-item" v-for="(teacher, index) of item.teachers" :key="index">
                    <img :src="teacher.headPortrait" :alt="teacher.teacherName">
                    <p class="name">{{teacher.teacherName}}</p>
                  </div>
                </div>
                <div class="ib-right">
                  <p class="isPay">已支付</p>
                </div>
              </div>
            </li>
          </van-list>
        </ul>
        <van-empty v-if="courseGroupList.length === 0" description="暂无数据" />
      </div>
      
    </div>
  </div>
</template>
<script>
import {getOwnCourseGroup} from '@/api/api'
import qs from 'qs'
export default {
  name:'course',
  data() {
    return{
      courseGroupList: [], // 课程组列表
      loading: false,
      finished: false,
    }
  },
  mounted(){
    this.getCourseGroup()
  },
  methods: {
    pageChange(page) {
      this.page = page
      this.getCourseGroup();
    },
    jumpDetail(item) {
      this.$router.push({path:`/course/${item.id}`})
    },
    // 获取课程组
    async getCourseGroup() {
      const params = {
        page: 1,
        limit: 1000
      }
      let response = await getOwnCourseGroup(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.courseGroupList = response.data
      this.total = response.total
      this.loading = false;
      this.finished = true;
    },
  }
}
</script>
<style lang="less">
.courseContain{
  width: 94%;
  padding:0 3%;
  overflow: hidden;
  // background:#fff;
  min-height: 100%;
  border-left:1px solid #E8E8E8;
  float:left;
  .course_list{
    .ul{
      margin:0;
      padding-bottom:1rem;
      overflow: hidden;
      .li{
        margin:0.4rem 3% 0 3%;
        padding:0 0.3rem 0.2rem 0.3rem ;
        background: #FFFFFF;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
          transform: translateY(-5px);
        }
        .tag{
          // position: absolute;
          width: 2.22rem;
          height: 0.49rem;
          line-height: 0.49rem;
          font-size: 12px;
          text-align: center;
          color: #fff;
          // top:0;
          // left: 3%;
          background:url('../../assets/images/courseGroup/tag.png') center no-repeat;
          background-size: contain;
          user-select: none;
        }
        .title{
          height: 1rem;
          line-height: 0.45rem;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          padding: 0.25rem 0;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tip{
          font-size: 12px;
          height: 12px;
          line-height: 12px;
          font-weight: 400;
          color: #ADADAD;
          margin-bottom: 0.2rem;
          overflow: hidden;
          text-overflow:ellipsis; 
          white-space: nowrap;
        }
        .bottom-box{
            width:100%;
            height:1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          .teacher-list{
            display: flex;
            height:1rem;
            .teacher-item{
              width: 1rem;
              margin-right: 0.1rem;
              height: 1rem;
              border-radius: 50%;
              // overflow: hidden;
              img{
                display: block;
                width: 0.6rem;
                height: 0.6rem;
                overflow: hidden;
                margin-bottom: 0.06rem;
                margin-left:0.2rem;
                border-radius: 50%;
              }
              .name{
                width: 0.6rem;
                height: 0.4rem;
                line-height: 0.4rem;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                width: 100%;
              }
            
            }
            
          }
          .ib-right{
            // width: 95px;
            padding:0.1rem 0.25rem;
            border:1px solid #00B262;
            color:#00B262;
            font-size: 14px;
            flex-grow:0;
            flex-shrink:0;
          }
        }
        
        
      }
      .li:nth-child(3n){
        margin-right: 0px; 
      }
    }  
  }
}

</style>
