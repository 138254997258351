<!--课程协议-->
<template>
    <div>
        <div class="protocolContain">
            <div class="protocol_list">
                <ul>
                    <li v-for="(item, index) in protocolList" :key="index" @click="protocolDetail(item)">
                        <p>{{item.protocolName}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { getProtocolList} from '@/api/api'
export default {
    name:'protocol',
    data(){
        return{
            page: 1,
            limit: 1000,
            protocolList:[],
        }
    },
    mounted(){
        this.getProtocolList()
    },
    methods:{
      // 获取订单
      async getProtocolList() {
        const params = {
            page: this.page,
            limit: this.limit
        }
        let response = await getProtocolList(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.protocolList = response.data
      },

      protocolDetail(item){
        this.$router.push({path: `/mine/protocolDetail?id=${item.id}`})
      }
    },
    
    
}
</script>
<style lang="less" scoped>
.protocolContain{
    width:90%;
    padding:0 5%;
    overflow: hidden;
    background:#fff;
    .protocol_list{
        ul{
            margin:0;
            padding:0;
            li{
                width:100%;
                height:1rem;
                line-height: 1rem;
                border-bottom:1px solid #f2f2f5;
                cursor: pointer;
                
               p{
                   font-size: 16px;
                   color:#333333;
                   text-align: left;
               }
                
            }  
            
        } 
    }
    
    
    
}
</style>
